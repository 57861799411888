import * as React from 'react'
import classNames from 'classnames'
import {useColors} from '../../../../hooks/colors'
import {ModalType, useModal} from '../../../../hooks/modal'
import s from './updated-indication.scss'
import {UpdatedIndicationProps} from '.'

export const UpdatedIndication = ({formattedUpdatedDate, t}: UpdatedIndicationProps) => {
  const {insideModal, modalType} = useModal()
  const insideItemModal = modalType === ModalType.SCHEDULE_ITEM

  const {isDarkActionColor} = useColors()
  const wired = isDarkActionColor()

  return (
    <div
      className={classNames(s.container, {
        [s.insideModal]: insideModal,
        [s.wired]: wired,
        [s.insideItemModal]: insideItemModal,
      })}
    >
      <div className={s.pimple} />
      <div className={s.updatedIndicationText}>
        {t('updated')} {formattedUpdatedDate}
      </div>
    </div>
  )
}
