import React, {useState} from 'react'
import {Dialog} from 'wix-ui-tpa/Dialog'
import {Button} from 'wix-ui-tpa/Button'
import {Text, TYPOGRAPHY} from 'wix-ui-tpa/Text'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {addCircularTabFocus} from '@wix/wix-events-commons-statics'
import {useColors} from '../../../hooks/colors'
import {useWidgetProps} from '../../../hooks/widget-props'
import {Schedule} from '../../schedule/schedule'
import {ItemDivider} from '../../schedule/divider/divider'
import {ModalContext, ModalType} from '../../../hooks/modal'
import {classes as modalClasses} from '../modal.st.css'
import {DH} from '../../../data-hooks'
import s from './personal-agenda.scss'
import {EmptyPersonalAgenda} from './empty-personal-agenda'
import {classes as st} from './personal-agenda.st.css'
import {useModalBookmarkedDays} from './hooks'

export const PersonalAgendaModal = () => {
  const {togglePersonalAgendaModal, bookmarksCount, host, t} = useWidgetProps()
  const {days} = useModalBookmarkedDays()
  const {isMobile} = useEnvironment()
  const {isDarkActionColor} = useColors()
  const [cachedBookmarksCount] = useState(bookmarksCount)
  const preview = host.viewMode === 'Preview'
  const closeButton = React.useRef(null)

  React.useEffect(() => {
    addCircularTabFocus('[data-hook="close-btn"]', `[data-hook="${DH.PERSONAL_AGENDA_DONE_BUTTON}"]`)
    closeButton.current.focus()

    return () => {
      const myAgendaButton = document.querySelector(`[data-hook="${DH.MY_AGENDA_BUTTON}"]`) as HTMLButtonElement
      myAgendaButton.focus()
    }
  }, [])

  const handleClose = () => {
    togglePersonalAgendaModal()
  }

  const wired = isDarkActionColor()

  return (
    <ModalContext.Provider value={{insideModal: true, modalType: ModalType.DEFAULT}}>
      <Dialog
        closeButtonRef={closeButton}
        manualFocus={true}
        className={classNames(modalClasses.root, {
          [modalClasses.previewMode]: preview,
          [modalClasses.mobile]: isMobile,
        })}
        contentClassName={classNames(s.modal, {[s.mobile]: isMobile})}
        isOpen={true}
        onClose={handleClose}
        wiredToSiteColors={false}
        closeButtonAriaLabel={t('aria.dialog.close')}
      >
        <div className={s.modalContent}>
          <div className={s.header}>
            <div>
              <Text className={st.blackText} typography={TYPOGRAPHY.smallTitle} tagName="h2">
                {t('myAgenda.modal.title')}
              </Text>
            </div>
            <div className={s.subtitle}>
              <Text className={classNames(st.blackText, st.subtitle)} typography={TYPOGRAPHY.runningText}>
                {bookmarksCount === 1
                  ? t('myAgenda.modal.subtitle.singular', {count: bookmarksCount})
                  : t('myAgenda.modal.subtitle.plural', {count: bookmarksCount})}
              </Text>
            </div>
          </div>
          <div className={s.content} data-hook={DH.PERSONAL_AGENDA_MODAL_CONTENT}>
            {cachedBookmarksCount ? (
              <>
                <Schedule days={days} />
                <ItemDivider />
              </>
            ) : (
              <EmptyPersonalAgenda />
            )}
          </div>
          <div className={s.button}>
            <Button
              data-hook={DH.PERSONAL_AGENDA_DONE_BUTTON}
              className={wired ? st.buttonWired : st.buttonBlackWhite}
              upgrade
              onClick={handleClose}
            >
              {t('myAgenda.modal.closeButton')}
            </Button>
          </div>
        </div>
      </Dialog>
    </ModalContext.Provider>
  )
}
