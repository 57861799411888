import * as React from 'react'
import {NOTIFICATION_SIZE, NOTIFICATION_TYPE} from 'wix-ui-tpa/SectionNotification'
import classNames from 'classnames'
import {Error} from '../icons'
import {SectionNotification} from '../schedule/item/section-notification'
import {UpdatedIndication} from '../schedule/item/updated-indication'
import {useModal} from '../../hooks/modal'
import s from './indications.scss'
import {IndicationsProps} from '.'

export const Indications = ({canceled, t, formattedUpdatedDate}: IndicationsProps) => {
  const {insideModal} = useModal()
  const shouldUpdatedIndicationShow = !canceled && formattedUpdatedDate

  return (
    <>
      {canceled ? (
        <SectionNotification
          size={NOTIFICATION_SIZE.compact}
          type={NOTIFICATION_TYPE.wired}
          className={classNames(s.cancelNotification, {[s.insideModal]: insideModal})}
          icon={<Error className={s.canceledIcon} />}
          text={t('itemCanceled')}
        />
      ) : null}
      {shouldUpdatedIndicationShow ? <UpdatedIndication t={t} formattedUpdatedDate={formattedUpdatedDate} /> : null}
    </>
  )
}
